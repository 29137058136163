
import Table from 'react-bootstrap/Table';

function KobSkali_2022() {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr><th>Място</th><th>Име</th><th>Време</th></tr>
      </thead>
      <tbody>
      

        <tr><th colSpan={3}>МЪЖЕ до 39 години</th></tr>
        <tr><td>1</td><td>Ивайло Вълев </td><td>01:07:52</td></tr>
        <tr><td>2</td><td>Димитър Павлов</td><td>01:27:29</td></tr>
        <tr><td>3</td><td>Георги Станчев </td><td>01:28:36</td></tr>
        <tr><td>4</td><td>Давид Станиславов</td><td>01:28:51</td></tr>
        <tr><td>5</td><td>Георги Георгиев </td><td>01:29:21</td></tr>
        <tr><td>6</td><td>Виктор Симеонов </td><td>01:30:37</td></tr>
        <tr><td>7</td><td>Тихомир Христов </td><td>01:40:16</td></tr>
        <tr><td>8</td><td>Владимир Матеев </td><td>01:53:16</td></tr>
        <tr><td>9</td><td>Янек Павлов </td><td>01:55:43</td></tr>
        <tr><td>10</td><td>Георги Стоянов </td><td>01:58:35</td></tr>
        <tr><td>11</td><td>Владимир Бошнаков </td><td>02:18:07</td></tr>
        <tr><td>12</td><td>Славомир Славчев </td><td>02:19:58</td></tr>
        <tr><td>13</td><td>Хин- Виетнам </td><td>02:21:25</td></tr>
        

        <tr><th colSpan={3}>МЪЖЕ 40+</th></tr>
        <tr><td>1</td><td>Петър Светиев</td><td>01:23:49</td></tr>
        <tr><td>2</td><td>Николай Миленков</td><td>01:25:25</td></tr>
        <tr><td>3</td><td>Владислав Маринов</td><td>01:28:51</td></tr>
        <tr><td>4</td><td>Данаил Дамянов</td><td>01:34:08</td></tr>
        <tr><td>5</td><td>Тихомир Цонков</td><td>01:35:52</td></tr>
        <tr><td>6</td><td>Димитър Мавродиев</td><td>01:37:50</td></tr>
        <tr><td>7</td><td>Светослав Светиев</td><td>01:38:06</td></tr>
        <tr><td>8</td><td>Красимир Спасов</td><td>01:38:16</td></tr>
        <tr><td>9</td><td>Николай Владимиров</td><td>01:44:20</td></tr>
        <tr><td>10</td><td>Красимир Жеков</td><td>01:50:10</td></tr>
        <tr><td>11</td><td>Даниел Йорданов</td><td>01:51:20</td></tr>


        <tr><th colSpan={3}>ЖЕНИ до 39 години</th></tr>
        <tr><td>1</td><td>Венелина Емилова</td><td>01:34:01</td></tr>
        <tr><td>2</td><td>Констанс Хесапчиева</td><td>01:36:16</td></tr>
        <tr><td>3</td><td>Доминикия Карагуца</td><td>01:40:20</td></tr>
        <tr><td>4</td><td>Аделина Григорова</td><td>01:49:34</td></tr>
        <tr><td>5</td><td>Диляна Найденова</td><td>01:58:44</td></tr>
        <tr><td>6</td><td>Вера Терзиева</td><td>02:18:10</td></tr>
        <tr><td>7</td><td>Елизабет Димитрова</td><td>02:19:58</td></tr>

        <tr><th colSpan={3}>ЖЕНИ 40+</th></tr>
        <tr><td>1</td><td>Елена Тодорова</td><td>01:38:59</td></tr>
        <tr><td>2</td><td>Дани Еленкова</td><td>01:52:18</td></tr>
        <tr><td>3</td><td>Тонислава Сотирова</td><td>01:59:13</td></tr>

      </tbody>
    </Table>
  );
}

export default KobSkali_2022;

