
import Table from 'react-bootstrap/Table';



  function VratzataRun() {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          
          <tr><th>Място</th><th>Име</th><th>Време</th></tr>
        </thead>
        <tbody>
        

          <tr><th colSpan={3}>Жени 40+ години</th></tr>
          
          <tr><td>1</td><td>Мария Цочева </td><td>01:30:10</td></tr>
          <tr><td>2</td> <td>Росица Белева </td><td>01:31:12</td></tr>
          <tr><td>3</td><td>Ирена Спасова</td><td>01:33:05</td></tr>
          <tr><td>4</td><td>Милена Абрашева </td><td>01:48:50</td></tr> 
          <tr><td>5</td><td>Тонислава Сотирова</td><td>02:02:25</td></tr>
          <tr><td>6</td><td>Мая Петкова </td><td>02:08:09</td></tr>
          <tr><td>7</td><td>Теодора Кюлафлийска </td><td>02:08:56</td></tr>
          <tr><td>8</td><td>Гергана Дойчинова </td><td>02:33:25</td></tr>
          <tr><td>9</td><td>Йорданка Еленкова </td><td>DNF</td></tr>
          <tr><td>10</td><td>Христина Нестерова </td><td>DNF</td></tr>


          <tr><th colSpan={3}>Жени до 39 години</th></tr>
         
          <tr><td>1</td><td>Михаела Димитрова </td><td>01:30:20</td></tr>
          <tr><td>2</td><td>Констанс Хесапчиева </td><td>01:40:40</td></tr>
          <tr><td>3</td><td>Жаклина Жекова</td><td>01:44:47</td></tr>
          <tr><td>4</td><td>Камелия Стойчева </td><td>01:46:18</td></tr>
          <tr><td>5</td><td>Весела Стоянова</td><td>01:52:27</td></tr>
          <tr><td>6</td><td>Аделина Григорова </td><td>01:58:09</td></tr>
          <tr><td>7</td><td>Цветелина Христова</td><td>02:08:25</td></tr>
          <tr><td>8</td><td>Радост Ангелова</td> <td>02:55:51</td></tr>

          


          <tr><th colSpan={3}>Мъже 40+ години</th></tr>
          <tr><td>1</td><td>	Найден Алексов</td><td> 	01:15:21</td></tr>
          <tr><td>2</td><td>	Цецо Стоянов</td><td> 	01:15:26</td></tr>
          <tr><td>3</td><td>	Иван Димитров</td><td> 	01:21:23</td></tr>
          <tr><td>4</td><td>	Данаил Дамянов</td><td> 	01:22:51</td></tr>
          <tr><td>5</td><td>	Милен Тончев</td><td>	01:28:53</td></tr>
          <tr><td>6</td><td>	Стилиян Стоянов</td><td> 	01:32:25</td></tr>
          <tr><td>7</td><td>	Николай Миленков</td><td> 	01:33:07</td></tr>
          <tr><td>8</td><td>	Марио Никифоров</td><td> 	01:38:20</td></tr>
          <tr><td>9</td><td>	Мирослав Крайнев</td><td> 	01:40:27</td></tr>
          <tr><td>10</td><td>	Красимир Спасов</td><td> 	01:58:59</td></tr>
          <tr><td>11</td><td>	Дарин Димитров</td><td> 	02:09:04</td></tr>
          <tr><td>12</td><td>	Христо Генадиев</td><td> 	02:19:58</td></tr>
          <tr><td>13</td><td>	Евтим Стефанов</td><td> 	02:31:03</td></tr>
          <tr><td>14</td><td>	Светослав Петров</td><td> 	02:32:11</td></tr>
          <tr><td>15</td><td>	Емил Георгиев</td><td> 	02:33:21</td></tr>
          <tr><td>16</td><td>	Владислав Маринов</td><td> 	02:43:53</td></tr>
          <tr><td>17</td><td>	Даниел Йорданов</td><td>	02:55:52</td></tr>

          <tr><th colSpan={3}>Мъже до 39 години</th></tr>
          <tr><td>1</td><td>Ивайло Вълев</td><td> 	01:14:36</td></tr>
          <tr><td>2</td><td>Панайот Петров</td><td> 	01:29:38</td></tr>
          <tr><td>3</td><td>Адриян Бойчовски</td><td> 	01:36:28</td></tr>
          <tr><td>4</td><td>Роберт Йошев</td><td> 	01:38:40</td></tr>
          <tr><td>5</td><td>Иван Василев</td><td> 	01:39:19</td></tr>
          <tr><td>6</td><td>Пламен Велков</td><td>	01:39:29</td></tr>
          <tr><td>7</td><td>Ясен Маданков</td><td> 	01:41:42</td></tr>
          <tr><td>8</td><td>Станислав Крушовалиев</td><td> 	01:41:59</td></tr>
          <tr><td>9</td><td>Янис Василев</td><td>	01:43:57</td></tr>
          <tr><td>10</td><td>Румен Стаматов</td><td>	01:45:49</td></tr>
          <tr><td>11</td><td>Панайот Панайотов</td><td> 	01:51:17</td></tr>
          <tr><td>12</td><td>Любомир Матов</td><td>	01:51:54</td></tr>
          <tr><td>13</td><td>Петър Петков</td><td> 	01:57:18</td></tr>
          <tr><td>14</td><td>Светослав Марков</td><td>	02:01:16</td></tr>
          <tr><td>15</td><td>Цветелин Пецев</td><td> 	02:26:37</td></tr>
          <tr><td>16</td><td>Емил Стойчев</td><td>	02:29:22</td></tr>
          <tr><td>17</td><td>Стефан Стойчев</td><td> 	02:29:27</td></tr>
          <tr><td>18</td><td>Виктор Асенов</td><td> 	02:43:53</td></tr>
          <tr><td>19</td><td>Калоян Берберов</td><td>	02:55:51</td></tr>

          <tr><th colSpan={3}>Adventure-пешеходци</th></tr>
          <tr><td>1</td><td>Здравка Севестакиева </td><td></td></tr>

        </tbody>
      </Table>
    );
  }
 
  export default VratzataRun;

