import React, {useState, useEffect, useContext} from "react";
import { db } from "../utils/firebase";
import { collection, getDocs } from "firebase/firestore";
import Item from "./Item";
import styled from "styled-components";
import { motion } from "framer-motion";
import AuthNav from "../auth/AuthNav";
import { IsAuthenticated } from "../context/UserContext";

const AllItems = () => {
  const { user } = useContext(IsAuthenticated);
  const KobileniSteniCollectionRef = collection(db, "kobileniskali2022");
  const MidjurRunCollectionRef = collection(db, "MidjurRun2022");
  const MaguraRunCollectionRef = collection(db, "MaguraRun_2022");
  const [kobileniSteni, setKobileniSteni] = useState([]);
  const [midjur2022, setMidjur2022] = useState([]);
  const [magura_run_2022, setMagura_run_2022] = useState([]);
  

  useEffect(() => {
    const getAll = async () => {
      const setDataAllKobileniSteni = await getDocs(KobileniSteniCollectionRef);
      const setDataAllMidjurRun2022 = await getDocs(MidjurRunCollectionRef);
      const setDataAllMaguraRun2022 = await getDocs(MaguraRunCollectionRef);
     
      setKobileniSteni(
        setDataAllKobileniSteni.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setMidjur2022(
        setDataAllMidjurRun2022.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setMagura_run_2022(
        setDataAllMaguraRun2022.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
   
    };
    getAll();
  }, []);

  return (
    <Container>
      <AuthNav />
      {user &&
      <>
      <h1>Кобилени стени 08.2022</h1>
      <ItemsContainer>
        {kobileniSteni.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Миджур Run 2022</h1>
      <ItemsContainer>
        {midjur2022.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
      <HR />
      <h1>Магура run 2022</h1>
      <ItemsContainer>
        {magura_run_2022.map((item) => {
          return <Item key={item.id} {...item} />;
        })}
      </ItemsContainer>
   
      </>}
    </Container>
  );
};
const HR = styled.div`
  border-bottom: 1px solid gray;
  margin: 3px;
  opacity: 0.7;
  width: 100%;
`;
const ItemsContainer = styled(motion.div)`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;
export default AllItems;
