import React, { useEffect, useState, useCallback } from "react";
import styled from 'styled-components'
import Img1 from "../files/aboutUs/1.jpg"
import Img2 from "../files/aboutUs/2.jpg"
import Img3 from "../files/aboutUs/3.jpg"
import Img4 from "../files/aboutUs/4.jpg"
import Img5 from "../files/aboutUs/5.jpg"
import Img6 from "../files/aboutUs/6.jpg"
import Img7 from "../files/aboutUs/7.jpg"
import Img8 from "../files/aboutUs/8.jpg"
import Img9 from "../files/aboutUs/9.jpg"
import Img10 from "../files/aboutUs/10.jpg"
import Img11 from "../files/aboutUs/11.jpg"
import Img12 from "../files/aboutUs/12.jpg"
import Img13 from "../files/aboutUs/13.jpg"
import Img14 from "../files/aboutUs/14.jpg"
import Img15 from "../files/aboutUs/15.jpg"
import Img16 from "../files/aboutUs/16.jpg"
import Img17 from "../files/aboutUs/17.jpg"
import Img18 from "../files/aboutUs/18.jpg"
import Img19 from "../files/aboutUs/19.jpg"
import Img20 from "../files/aboutUs/20.jpg"
import Img21 from "../files/aboutUs/21.jpg"
import Img22 from "../files/aboutUs/22.jpg"
import Img23 from "../files/aboutUs/23.jpg"
import Img24 from "../files/aboutUs/24.jpg"
import Img25 from "../files/aboutUs/25.jpg"
import Img26 from "../files/aboutUs/26.jpg"
import Img27 from "../files/aboutUs/27.jpg"
import Img28 from "../files/aboutUs/28.jpg"
import Img29 from "../files/aboutUs/29.jpg"
import Img30 from "../files/aboutUs/30.jpg"
import Img31 from "../files/aboutUs/31.jpg"
import Img32 from "../files/aboutUs/32.jpg"
import Img33 from "../files/aboutUs/33.jpg"
import Img34 from "../files/aboutUs/34.jpg"
import Img35 from "../files/aboutUs/35.jpg"
import Img36 from "../files/aboutUs/36.jpg"
import Img37 from "../files/aboutUs/37.jpg"
import Img38 from "../files/aboutUs/38.jpg"
import Img39 from "../files/aboutUs/39.jpg"
import Img40 from "../files/aboutUs/40.jpg"
import Img41 from "../files/aboutUs/41.jpg"
import Img42 from "../files/aboutUs/42.jpg"
import Img43 from "../files/aboutUs/43.jpg"
import Img44 from "../files/aboutUs/44.jpg"
import Img45 from "../files/aboutUs/45.jpg"

import {motion} from "framer-motion"
import { pageAnimation } from '../animation';
import Footer from "./Footer"

import { db } from "./utils/firebase";
import { collection, addDoc } from "firebase/firestore";

import Gallery from "react-photo-gallery";
import {  getDocs } from "firebase/firestore";

const AboutUs = () => {
    
  //LIGHTBOX
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
 

    const itemsCollectionRef = collection(db, "kobileniskali2022");
    const [items, setItems] = useState([{}]);
  
  
  
const photos = [
       
    {src: Img1, width: 4, height: 3},
    {src: Img2, width: 5, height: 3},
    {src: Img3,width: 4,height: 3},
    {src: Img4,width: 4,height: 3},
    {src: Img5,width: 3,height: 4 },
    {src: Img6,width: 4,height: 3},
    {src: Img7,width: 3,height: 4},
    {src: Img8, width: 3, height: 4},
    {src: Img9,width: 4, height: 3},
        {
            src: Img10,
          width: 3,
          height: 4
        },
        {
            src: Img11,
          width: 3,
          height: 4
        },
        {
            src: Img12,
          width: 4,
          height: 3
        },
        {
            src: Img13,
          width: 3,
          height: 4
        },
        {
            src: Img14,
          width: 4,
          height: 3
        },
        {
            src: Img15,
          width: 4,
          height: 3
        },
        {
            src: Img16,
            width: 4,
            height: 3
        },
        {
            src: Img17,
            width: 4,
            height: 3
        },
        {
            src: Img18,
            width: 4,
            height: 3
        },
        {
            src: Img19,
            width: 4,
            height: 3
        },
     
        {src: Img20, width: 3, height: 4},
        {src: Img21, width: 4, height: 3},
        {src: Img22, width: 3, height: 4},
        {src: Img23, width: 4, height: 3},
        {src: Img24, width: 4, height: 3},
        {src: Img25, width: 4, height: 3},
        {src: Img26, width: 3, height: 4},
        {src: Img27, width: 3, height: 4},
        {src: Img28, width: 4, height: 3},
        {src: Img29, width: 4, height: 3},
        {src: Img30, width: 4, height: 3},
        {src: Img31, width: 4, height: 3},
        {src: Img32, width: 3, height: 4},
        {src: Img33, width: 4, height: 3},
        {src: Img34, width: 4, height: 3},
        {src: Img35, width: 4, height: 3},
        {src: Img36, width: 4, height: 3},
        {src: Img37, width: 4, height: 3},
        {src: Img38, width: 4, height: 3},
        {src: Img39, width: 3, height: 4},
        {src: Img40, width: 3, height: 4},
        {src: Img41, width: 3, height: 4},
        {src: Img42, width: 4, height: 3},
        {src: Img43, width: 4, height: 3},
        {src: Img44, width: 4, height: 3},
        {src: Img45, width: 4, height: 3},
]
  
    useEffect(() => {
      const getItems = async () => {
        const setDataAll = await getDocs(itemsCollectionRef);
        setItems(
          setDataAll.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            key: doc.id,
          }))
        );
      };
      getItems();
    }, []);

  


      
    return(
        <>
    <Container 
    variants={pageAnimation} 
    initial="hidden" 
    animate="show"
    exit="exit"
    style={{background:"#fff"}}
    >
         <Gallery photos={photos} direction={"row"} margin={0.5} targetRowHeight={135} />
        
        <HeadingCubeImg> 
    <Title >За нас</Title>
    <Heading>
        Организацията Bg Events and Guiding е създадена 2014 година. Идеята за това бе да се покажат красотите на СевероЗАПАЗЕНА България. Започнахме с 5 събития в района на Враца, а в момента са 16 в цяла Северозападна България. Най-атрактивни от тях са Крета Run, двете ултра дистанции У Царството на ТрибАлете и Боровица Ултра, Вертикален километър Миджур и Копрен, като другите не отстъпват по нищо. Последните три години събираме над 1000 участника на година. Все още сме “бутикова организация” издържаща се със собствени средства, но в това е чарът ни. <br /><br />
    </Heading>
    <Subtitle>Може да се свържете с нас на:</Subtitle>   
    <Heading>
       <Paragraph>Email: bgeventsandguiding@gmail.com, alexanderbikespro@gmail.com</Paragraph>
       <Paragraph>Телефон: <PhoneLink href="tel:0878 259 772">0878 259 772</PhoneLink></Paragraph>
    </Heading>                   
                                </HeadingCubeImg>
       
    </Container>
 <Footer />
 </>
  )
}

const Paragraph= styled.p`
color:inherit;
text-align: left;
font-style: italic;
opacity: 0.9;
text-decoration: none;
`
const PhoneLink = styled.a`
color:inherit;
text-decoration: none;
`
const HeadingCubeImg = styled.h3` 
    position: absolute;
    top: 50%;
    left: 50%;
    color:#484748;
    font-size:14px;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    font-weight: 400;
    font-family: 'Montserrat',sans-serif;
    background-color:#ffffff;
    padding:40px;
    width:50vw;
 
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
box-shadow: 5px 5px 15px 5px #000000;
    @media (max-width: 768px) {  
        width:90vw;
    }
`
const Container = styled(motion.div)` 
    align-items: center;
    overflow-y: hidden;
    width:100%;
    height:calc(100vh - 100px);
    margin-top:60px;
    opacity: 0.9;
`

const Title =styled.h2`
text-align:center;
padding:10px;
`
const Subtitle =styled.p`
text-align:left;
font-size:16px;
font-weight: 700;
`



const Heading = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;



export default AboutUs