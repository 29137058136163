
import Table from 'react-bootstrap/Table';



  function Koledno_Ochin_Dol() {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          
          <tr><th>Място</th><th>Име</th><th>Време</th></tr>
        </thead>
        <tbody>
        

          <tr><th colSpan={3}>Жени 40+ години</th></tr>


          <tr><td>1</td><td>	Милена Абрашева </td><td>	01:36:55</td></tr>
          <tr><td>2</td><td>	Мая Петкова </td><td>	01:42:08</td></tr>
          <tr><td>3</td><td>	Теодора Кюлафлийска </td><td>	01:49:22</td></tr>
          <tr><td>4</td><td>	Елизабет Йорданова </td><td>	01:54:09</td></tr>
          <tr><td>5</td><td>	Катя Харискова </td><td>	02:21:45</td></tr>





          <tr><th colSpan={3}>Жени до 39 години</th></tr>
         
          <tr><td>1</td><td>Деница Добрева </td><td>01:31:48</td></tr>
          <tr><td>2</td><td>Станислава Георгиева </td><td>01:47:33</td></tr>
         


          <tr><th colSpan={3}>Мъже 40+ години</th></tr>

          <tr><td>1</td><td>	Данаил Дамянов 	</td><td>01:18:38</td></tr>
          <tr><td>2</td><td>	Сергей Пантелеев</td><td>	01:20:55</td></tr>
          <tr><td>3</td><td>	Гълъб Топалов</td><td>	01:26:29</td></tr>
          <tr><td>4</td><td>	Антоан Тодоров</td><td>	02:00:18</td></tr>
          <tr><td>5</td><td>	Христо Генадиев </td><td>	02:04:33</td></tr>
          <tr><td>6</td><td>	Валентин Василев </td><td>	02:17:30</td></tr>

          <tr><th colSpan={3}>Мъже до 39 години</th></tr>

          <tr><td>1</td><td>	 Ивайло Вълев </td><td>	01:09:28</td></tr>
          <tr><td>2</td><td>	Пламен Велков </td><td>	01:16:12</td></tr>
          <tr><td>3</td><td>	Калоян Кръстев </td><td>	01:17:04</td></tr>
          <tr><td>4</td><td>	Левон Топакбашиян </td><td>	01:26:18</td></tr>
          <tr><td>5</td><td>	Цветелин Цветанов </td><td>	01:26:39</td></tr>
          <tr><td>6</td><td>	Станислав Крушовалиев </td><td>	01:28:44</td></tr>
          <tr><td>7</td><td>	Светослав Марков	</td><td>01:33:33</td></tr>
          <tr><td>8</td><td>	Иван Иванов	</td><td>01:50:10</td></tr>
          <tr><td>9</td><td>	Златомир Иванов	</td><td>01:50:10</td></tr>
          <tr><td>10</td><td>	Янек Павлов </td><td>	DNF</td></tr>
          <tr><td>11</td><td>	Йоан Йорданов </td><td>	DNF</td></tr>

          <tr><th colSpan={3}>Дълго трасе жени 22км</th></tr>
          
          <tr><td>1</td><td>Елена Тодорова </td><td>03:04:26</td></tr>
          <tr><td>2</td><td>Констанс Хесапчиева  </td><td>03:51:23</td></tr>

          <tr><th colSpan={3}>Дълго трасе мъже 22км</th></tr>

          <tr><td>1</td><td>	Кръстьо Панчев 	</td><td>02:50:30</td></tr>
          <tr><td>2</td><td>	Владислав Маринов </td><td>	03:04:27</td></tr>
          <tr><td>3</td><td>	Димитър Кобаков</td><td>03:32:04</td></tr>
          <tr><td>4</td><td>	Мирослав Крайнев-само една обиколка 11 km </td><td>	DNF</td></tr>
          <tr><td>5</td><td>	Александър Първанов-само една обиколка 11 km</td><td>	DNF </td></tr>
        </tbody>
      </Table>
    );
  }
 
  export default Koledno_Ochin_Dol;

