import React, {useState} from 'react'
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from 'react-bootstrap/Collapse';
import styled from "styled-components"
const ToggleButton = ({props}) => {
    const [open, setOpen] = useState(false);
  return (
    <>   
      {!open ?<InfoText>Повече информация... </InfoText> : ""}
      <Collapse in={open}>
        <span dangerouslySetInnerHTML={{__html: props}}  style={{whiteSpace :" pre-wrap"}}/>
       
      </Collapse>
      {open ? <IconButton 
        size="large"
        color = "inherit"
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
         <ExpandLessIcon />
      </IconButton > : <IconButton 
        size="large"
        color = "inherit"
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        <ExpandMoreIcon />
      </IconButton >}
      </>
  )
}
const InfoText = styled.span`
padding:0px;
`
export default ToggleButton