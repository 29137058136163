import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { db } from "../utils/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore"; 

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import AuthNav from "../auth/AuthNav";

const AddCompetition = () => {
  const [popUp, setPopUp] = useState(false);
  const storage = getStorage();
  const [images, setImages] = useState([])
  const [urls, setUrls] = useState([])
  const [place, setPlace] = useState("")
  const [typeF, setTypeF] = useState("")
  const [subtitle, setSubtitle] = useState("")
  const [id, setId] = useState("")
  const [identification, setIdentification] = useState("")
  const [title, setTitle] = useState("")
  const [date, setDate] = useState("")
  const [hour, setHour] = useState("")
  const [route1, setRoute1] = useState("")
  const [route2, setRoute2] = useState("")
  const [route3, setRoute3] = useState("")
  const [route4, setRoute4] = useState("")
  const [participants, setParticipants] = useState([])
  const [description, setDescription] = useState("")
  const navigate = useNavigate();
  
  
  const handleChangeInput = (e)=>{
    for (let i =0; i<e.target.files.length; i++){
      const newImage = e.target.files[i]
      newImage["id"] = uuidv4()
      setImages((prevState)=>[...prevState, newImage])
    }
  
  }
  const onSubmitHandler = async (e) => {
    e.preventDefault();
   setTypeF(e.target.title.value)
   setSubtitle(e.target.subtitle.value)
   setTitle(e.target.title.value)
   setId(e.target.id.value)
   setIdentification(e.target.identification.value)
   setDate(e.target.date.value)
   setHour(e.target.hour.value)
   setPlace(e.target.place.value)
   setRoute1(e.target.route1.value)
   setRoute2(e.target.route2.value)
   setRoute3(e.target.route3.value)
   setRoute4(e.target.route4.value)
   setDescription(e.target.description.value)
   

for (let i=0; i<images.length; i++) {
    const storageRef = ref(storage, `competitionsInfoImges/${e.target.identification.value}/${images[i].name}`)
     uploadBytes(storageRef, images[i]).then(()=>{
      getDownloadURL(storageRef)
        .then((url) => {
        console.log (url)
        setUrls((urls)=>[...urls, url])
       })})
    }

  }

  useEffect(()=>{
    console.log (typeF)
    const uploadDoc = async()=>{
      
      await setDoc(doc(db, "competitionsInfo", identification), 
      {urls,
      title,
      id,
      participants,
      identification,
      routes :[route1, route2, route3, route4],
      date,
      subtitle,
      hour,
      place,
      description}).then(setPopUp(true))
}

    uploadDoc()
  }, [urls])

  return (
    <Wrapper>
    <AuthNav />
    {!popUp && (
  <FormWrapper>
     <h1 className="title">Добави състезание</h1>
<form onSubmit={onSubmitHandler} id="form">
  <Form.Label htmlFor="title">Име на състезание</Form.Label>
  <Form.Control
        type="text"
        name = "title"
        id="name"
        aria-describedby="passwordHelpBlock"
        placeholder="Име..."
      />
        <br />
        <Form.Label htmlFor="subtitle">Подзаглавие на състезание</Form.Label>
  <Form.Control
        type="text"
        name = "subtitle"
        id="subtitle"
        aria-describedby="passwordHelpBlock"
        placeholder="Подзаглавие..."
      />
       <br />
        <Form.Label htmlFor="id">Id</Form.Label>
  <Form.Control
        type="number"
        name = "id"
        id="id"
        aria-describedby="passwordHelpBlock"
        placeholder="id..."
      />
       <br />
        <Form.Label htmlFor="id">Идентификация</Form.Label>
  <Form.Control
        type="text"
        name = "identification"
        id="identification"
        aria-describedby="passwordHelpBlock"
        placeholder="Идентификация..."
      />

<br />
        <Form.Label htmlFor="route1">Трасе 1</Form.Label>
  <Form.Control
        type="text"
        name = "route1"
        id="route1"
        aria-describedby="passwordHelpBlock"
        placeholder="Трасе 1..."
      />

<br />
        <Form.Label htmlFor="route2">Трасе 2</Form.Label>
  <Form.Control
        type="text"
        name = "route2"
        id="route2"
        aria-describedby="passwordHelpBlock"
        placeholder="Трасе 2..."
      />
         <br />
        <Form.Label htmlFor="route3">Трасе 3</Form.Label>
  <Form.Control
        type="text"
        name = "route3"
        id="route3"
        aria-describedby="passwordHelpBlock"
        placeholder="Трасе 3..."
      />
         <br />
        <Form.Label htmlFor="route4">Трасе 4</Form.Label>
  <Form.Control
        type="text"
        name = "route4"
        id="route4"
        aria-describedby="passwordHelpBlock"
        placeholder="Трасе 4..."
      />
        <br />
      <Form.Label htmlFor="name">Дата</Form.Label>
      <Form.Control
        type="text"
        name = "date"
        id="date"
        aria-describedby="passwordHelpBlock"
        placeholder="Дата..."
      />
        <br />
        <Form.Label htmlFor="hour">Час</Form.Label>
        <Form.Control
        type="text"
        name = "hour"
        id="hour"
        aria-describedby="passwordHelpBlock"
        placeholder="Час..."
      />
        <br />
        <Form.Label htmlFor="hour">Място</Form.Label>
        <Form.Control
        type="text"
        name = "place"
        id="place"
        aria-describedby="passwordHelpBlock"
        placeholder="Място..."
      />
        <br />
        <Form.Label htmlFor="description">Описание</Form.Label>
        <Form.Control
          name = "description" 
          id="description"
          type="text-area"
          as="textarea"
          placeholder="Описание..."
          style={{ height: '100px' }}
        />
        <br />
        <Form.Label>Избери файлове</Form.Label>
        <Form.Control id = "item_img_url"  name = "item_img_url" type="file" multiple onChange={handleChangeInput} />
      <br />
      <Button type="submit" variant="outline-success">Добави състезание</Button>
    </form>
    </FormWrapper>
  )}
  {popUp && (
    <Success>
      Поздравеления, състезанието е добавено успешно!<br />     <br />
      <Button  variant="outline-success"  onClick = {()=> navigate("/auth")}>
        Обратно към списък c-panel
      </Button>
    </Success>
  )}
  </Wrapper>
  )
}

const Wrapper = styled.div`
margin-top:80px;
`
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width:1200px;
  padding:20px;
  margin:0 auto;
  margin-top:80px;
  justify-content: center;
`

const Success = styled.div`
  padding: 20px;
  border: 2px solid #212529;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  text-align: center;
  margin:0 auto;
  margin-top: 80px;
  width:90%;
`;
export default AddCompetition