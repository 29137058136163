
import Table from 'react-bootstrap/Table';



  function KoprenRun_2022() {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          
          <tr><th>Място</th><th>Име</th><th>Време</th></tr>
        </thead>
        <tbody>
        

          <tr><th colSpan={3}>Късо трасе до 40+ години ЖЕНИ</th></tr>
          <tr><td>1</td><td>Евелина Дамянова</td><td>01:26:41</td></tr>
          <tr><td>2</td><td>Катя Харискова</td><td>02:10:20</td></tr>

          <tr><th colSpan={3}>Дълго трасе до 39 години ЖЕНИ</th></tr>
          <tr><td>1</td><td>Христина Петрова</td><td>01:55:12</td></tr>
          <tr><td>2</td><td>Констанс Хесапчиева</td><td>02:22:47</td></tr>

          <tr><th colSpan={3}>Дълго трасе  40+  ЖЕНИ</th></tr>
          <tr><td>1</td><td>Елена Тодорова</td><td>02:11:55</td></tr>
          <tr><td>2</td><td>Милена Абрашева</td><td>02:31:54</td></tr>
          <tr><td>3</td><td>Ралица Цветкова </td><td>05:22:00</td></tr>
          <tr><td>4</td><td>Дани Еленкова</td><td>03:30:05</td></tr>

          <tr><th colSpan={3}>Късо трасе 40+ МЪЖЕ</th></tr>
          <tr><td>1</td><td>Красимир Спасов </td><td>01:21:23</td></tr>
          <tr><td>2</td><td>Валентин Василев</td><td>01:42:15</td></tr>
          <tr><td>3</td><td>Драгомир Драганов</td><td>03:40:05</td></tr>

          <tr><th colSpan={3}>Дълго трасе МЪЖЕ до 39 години </th></tr>
          <tr><td>1</td><td>Александър Александров</td><td>01:47:23</td></tr>
          <tr><td>2</td><td>Пламен Стефанов</td><td>02:25:07</td></tr>
          <tr><td>3</td><td>Младен Ценов</td><td>02:31:16</td></tr>
          <tr><td>4</td><td>Ивайло Вълев- контузия</td><td>03:32:04</td></tr>

          <tr><th colSpan={3}>Дълго трасе 40+ МЪЖЕ</th></tr>
          <tr><td>1</td><td>Иван Йосифов </td><td>01:51:53</td></tr>
          <tr><td>2</td><td>Елвис Стоянов </td><td>01:53:58</td></tr>

        </tbody>
      </Table>
    );
  }
 
  export default KoprenRun_2022;

