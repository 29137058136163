
import Table from 'react-bootstrap/Table';



  function KoprenRun_2022() {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          
          <tr><th>Място</th><th>Име</th><th>Време</th></tr>
        </thead>
        <tbody>
        

          <tr><th colSpan={3}>Жени 40+ години</th></tr>
          <tr><td>1</td><td>Милена Абрашева</td><td>01:44:41</td></tr>
          <tr><td>2</td><td>Дани Еленкова </td><td>01:56:15</td></tr>
          <tr><td>3</td><td>Марина Гетова</td><td>02:00:46</td></tr>
          <tr><td>4</td><td>Тонислава Сотирова + 6мин (не изпита бира)</td><td>02:02:43</td></tr>
          <tr><td>5</td><td>Катя Харискова</td><td>02:52:47</td></tr>
          <tr><td>6</td><td>Деница Петрова ( една обиколка)</td><td>DNF</td></tr>


          <tr><th colSpan={3}>Жени до 39 години</th></tr>
          <tr><td>1</td><td>Констанс Хесапчиева</td><td>01:36:27</td></tr>

          <tr><th colSpan={3}>Мъже 40+ години</th></tr>
          <tr><td>1</td><td>Николай Миленков</td><td>01:25:37</td></tr>
          <tr><td>2</td><td>Христо Кръстев</td><td>01:29:32</td></tr>
          <tr><td>3</td><td>Владислав Маринов</td><td>01:36:32</td></tr>
          <tr><td>4</td><td>Кънчо Костадинов</td><td>01:42:49</td></tr>
          <tr><td>5</td><td>Цветко Митов</td><td>01:49:50</td></tr>
          <tr><td>6</td><td>Валентин Василев  + 6мин (не изпита бира)</td><td>01:57:37</td></tr>
          <tr><td>7</td><td>Данаил Дамянов Виртуално от Гърция </td><td>01:10:48</td></tr>

          <tr><th colSpan={3}>Мъже до 39 години</th></tr>
          <tr><td>1</td><td>Крум Георгиев</td><td>01:18:28</td></tr>
          <tr><td>2</td><td>Борислав Миланов</td><td>01:23:15</td></tr>
          <tr><td>3</td><td>Мартин Петров</td><td>01:25:28</td></tr>
          <tr><td>4</td><td>Иван Иванов</td><td>01:29:04</td></tr>

          <tr><th colSpan={3}>Adventure-пешеходци</th></tr>
          <tr><td>1</td><td>Петя Донева</td><td></td></tr>

        </tbody>
      </Table>
    );
  }
 
  export default KoprenRun_2022;

