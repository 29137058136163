import React from "react";
import styled from "styled-components";
import MidjurRun from "../files/GalleryImages/2.jpg"
import MaguraRun from "../files/GalleryImages/MaguraRun.jpg"
import Awards from "../files/GalleryImages/Awards.jpg"
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { cubeAnimation } from "../animation";
import KobileniSteni08_2022 from "../files/GalleryImages/KobileniSteni08_2022.jpg";
import Opletnq_2022 from "../files/GalleryImages/Opletnq.jpg"
import img1 from "../files/GalleryImages/1.jpg"
import { pageAnimation } from '../animation';


const Gallery = () => {
  const li = document.getElementsByTagName("li")
  const transitionF = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  const handleSearch = () =>{
    const input = document.getElementById("search").value
    const filter = input.toUpperCase();
    const ul = document.getElementById("listItems");
    const li = ul.getElementsByClassName("li");
    console.log (li.length)
    for (let i = 0; i < li.length; i++) {
        let valueLi = li[i].getElementsByTagName("h3")[0].innerHTML;
        console.log (valueLi)
      if (valueLi.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
          console.log ("a")
      } else {
          li[i].style.display = "none";
          console.log ("b")
      }
    }
  }
  return (
    <Wrapper
    variants={pageAnimation} 
    initial="hidden" 
    animate="show"
    exit="exit"
    style={{background:"#fff"}}
    >
        <HeaderWrapper>
            <ImageHeader src={img1}/>
            <InputElement id="search" onChange={handleSearch} className="input-field" type="text" placeholder="Намери галерия..." name="search" />
        </HeaderWrapper>
      <UlElemеnt id="listItems">
      <Link to="/gallery/gallery-detailed/Awards">

          <ContainerPortfolio variants={cubeAnimation} className="li">
            <Title>Награди</Title>
            <ImageInContainer src={Awards} />
          </ContainerPortfolio>
        </Link>
      <Link to="/gallery/gallery-detailed/opletnqRun2022">
          <ContainerPortfolio variants={cubeAnimation} className="li">
            <Title>Оплетня Run 09.2022</Title>
            <ImageInContainer src={Opletnq_2022} />
          </ContainerPortfolio>
        </Link>
        <Link to="/gallery/gallery-detailed/kobileniskali2022">
          <ContainerPortfolio variants={cubeAnimation} className="li">
            <Title>Кобилени стени run 08.2022</Title>
            <ImageInContainer src={KobileniSteni08_2022} />
          </ContainerPortfolio>
        </Link>
        <Link to="/gallery/gallery-detailed/MidjurRun2022">
          <ContainerPortfolio variants={cubeAnimation} className="li">
            <Title>Миджур Run 08.2022</Title>
            <ImageInContainer src={MidjurRun} />
          </ContainerPortfolio>
        </Link>
        <Link to="/gallery/gallery-detailed/MaguraRun_2022">
          <ContainerPortfolio variants={cubeAnimation} className="li">
            <Title>Магура Run 07.2022</Title>
            <ImageInContainer src={MaguraRun} />
          </ContainerPortfolio>
        </Link>
      </UlElemеnt>
    </Wrapper>
  );
};

const Title = styled.h3` 
    margin-top:150px;
    margin-left:150px;
    text-align: center;
    width:100%;
    height:80px;
    color: #fff;
    font-size:1.1rem;
    transform: translate(-50%, -50%);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    background-color:rgba(85, 85, 85, 0.75);
    padding-top:30px;
    cursor: pointer;
`

const ImageInContainer = styled.img`
    object-fit: cover;
    width:300px;
    height:300px;
    margin-top:-238px;
  
  @media (max-width: 768px) {
  }
`;
const InputElement = styled.input`
  text-align: center;
  height:60px;
  width:400px;
  margin:0 auto;
  margin-top:-150px;
  color:gray;
    &:focus{
      outline: 0;
      color:gray
    }
`
const ContainerPortfolio = styled(motion.div)`
    display:flex;
    flex-direction: column;
    margin:10px;
    width:300px;
    height:300px;
    transition: all .3s linear; 
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    &:hover {
    filter: brightness(80%);
    box-shadow: rgba(0, 0, 0, 0.85) 0px 5px 15px;
    transition: all .3s linear; 
    cursor:pointer;
    scale:1.005;
    @media (max-width: 768px) {
      margin-top: 7px;
  }
    }
`
const UlElemеnt = styled(motion.div)`
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  width:100%;
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {

  }
`;
const ImageHeader = styled(motion.img)` 
    height:300px;
    object-fit:cover;
    margin-top:-60px;
`
const Wrapper = styled(motion.div)`
  margin-top: 60px;
  width:100vw;
  min-height: calc(100vh - 100px);
  overflow-y:hidden;
`;
const HeaderWrapper = styled.div` 
  display: flex;
  width:100vw;
  flex-direction: column;
  height:250px;
`
export default Gallery;
