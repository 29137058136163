
import Table from 'react-bootstrap/Table';



function MaguraRun_2022Res() {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr><th>Място</th><th>Име</th><th>Време</th></tr>
      </thead>
      <tbody>  

        <tr><th colSpan={3}>10 km трасе до  ЖЕНИ</th></tr>
        <tr><td>1</td><td>Вероника Вълканова </td><td>00:45:23</td></tr>
        <tr><td>2</td><td>Констанс Хесапчиева</td><td>00:53:30</td></tr>
        <tr><td>3</td><td>Десислава Николова </td><td>01:01:20</td></tr>
        <tr><td>4</td><td>Даниела Герасимова</td><td>01:33:23</td></tr>
        <tr><td>5</td><td>Елена Стоянова </td><td>01:47:28</td></tr>

        <tr><th colSpan={3}>10 km трасе МЪЖЕ</th></tr>
        <tr><td>1</td><td>Найден Алексов</td><td>00:44:42</td></tr>
        <tr><td>2</td><td>Данаил Дамянов</td><td>00:53:27</td></tr>
        <tr><td>3</td><td>Красимир Спасов</td><td>00:53:39</td></tr>
        <tr><td>4</td><td>Георги Илиев</td><td>00:56:06</td></tr>
        <tr><td>5</td><td>Янек Павлов</td><td>01:06:32</td></tr>
        <tr><td>6</td><td>Тони Тодоров</td><td>01:08:14</td></tr>

        <tr><th colSpan={3}>20km трасе  МЪЖЕ</th></tr>
        <tr><td>1</td><td>Борис Чакалов</td><td>01:37:45</td></tr>
        <tr><td>2</td><td>Елвис Стоянов</td><td>01:56:27</td></tr>
        <tr><td>3</td><td>Пламен Велков</td><td>02:14:27</td></tr>
        <tr><td>4</td><td>Христо Иванов</td><td>02:33:54</td></tr>
        <tr><td>5</td><td>Цветан Тодоров</td><td>DNF</td></tr>

        <tr><th colSpan={3}>22 km  трасе ЖЕНИ</th></tr>
        <tr><td>1</td><td>Надежда Соколова</td><td>01:37:45</td></tr>
        <tr><td>2</td><td>Елена Тодорова</td><td>02:14:17</td></tr>

        <tr><th colSpan={3}>42 km МЪЖЕ</th></tr>
        <tr><td>1</td><td>Милен Тончев</td><td>03:27:32</td></tr>
        <tr><td>2</td><td>Георги Георгиев</td><td>04:07:23</td></tr>

      </tbody>
    </Table>
  );
}

export default MaguraRun_2022Res;

