
import Table from 'react-bootstrap/Table';



  function BelogradchikRun() {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          
          <tr><th>Място</th><th>Име</th><th>Време</th></tr>
        </thead>
        <tbody>
        

          <tr><th colSpan={3}>Жени 40+ години</th></tr>
          <tr><td>1</td><td>Мария Цочева </td><td>01:18:03</td></tr>
          <tr><td>2</td><td>Милена Абрашева </td><td>01:24:02</td></tr>
          <tr><td>2</td><td>Катя Харискова </td><td>02:05:47</td></tr>




          <tr><th colSpan={3}>Жени до 39 години</th></tr>
          <tr><td>1</td><td>Антония Георгиева </td><td>01:02:28</td></tr>
          <tr><td>2</td><td>Елена Димитрова </td><td>01:03:34</td></tr>
          <tr><td>3</td><td>Маринела Цекова </td><td>01:03:43</td></tr>
          <tr><td>4</td><td>Надежда Ангелова</td><td>01:05:53</td></tr>
          <tr><td>5</td><td>Калина Тенева </td><td>01:15:43</td></tr>
          <tr><td>6</td><td>Весела Стоянова </td><td>01:17:21</td></tr>
          <tr><td>7</td><td>Камелия Стойчева </td><td>01:19:02</td></tr>
          <tr><td>8</td><td>Аделина Григорова </td><td>01:22:55</td></tr>
          <tr><td>9</td><td>Стилияна Костова </td><td>01:23:34</td></tr>
          <tr><td>10</td><td>Михаела Димитрова </td><td>01:30:31</td></tr>
          <tr><td>11</td><td>Цветелина Христова</td><td>01:31:50</td></tr>
          <tr><td>12</td><td>Станислава Георгиева </td><td>01:32:03</td></tr>


            

          <tr><th colSpan={3}>Мъже 40+ години</th></tr>
          <tr><td>1</td><td>Мирослав Живкичов </td><td>01:02:02</td></tr>
          <tr><td>2</td><td>Данаил Дамянов </td><td>01:08:28</td></tr>
          <tr><td>3</td><td>Иван Димитров </td><td>01:11:50</td></tr>
          <tr><td>4</td><td>Генади Чуканов </td><td>01:15:24</td></tr>
          <tr><td>5</td><td>Владислав Маринов</td><td>01:18:33</td></tr>
          <tr><td>6</td><td>Костадин Бъчваров </td><td>01:33:03</td></tr>
          <tr><td>7</td><td>Христо Генадиев </td><td>01:37:58</td></tr>
          <tr><td>8</td><td>Валентин Василев </td><td>01:44:35</td></tr>
          <tr><td>9</td><td>Евтим Стефанов</td><td>01:49:26</td></tr>
          <tr><td>10</td><td>Емил Бояджийски</td><td>01:51:27</td></tr>
          <tr><td>11</td><td>Георги Георгиев </td><td>01:55:39</td></tr>
          

          <tr><th colSpan={3}>Мъже до 39 години</th></tr>
          <tr><td>1</td><td>Николай Първанов</td><td>50:40.7</td></tr>
          <tr><td>2</td><td>Георги Жеков </td><td>50:59.2</td></tr>
          <tr><td>3</td><td>Евгени Попов </td><td>52:11.3</td></tr>
          <tr><td>4</td><td>Алекс Василев </td><td>54:26.9</td></tr>
          <tr><td>5</td><td>Румен Стаматов </td><td>01:03:14</td></tr>
          <tr><td>6</td><td>Александър Ангелов </td><td>01:03:27</td></tr>
          <tr><td>7</td><td>Марин Върбанов</td><td>01:03:34</td></tr>
          <tr><td>8</td><td>Емил Стойчев</td><td>01:11:49</td></tr>
          <tr><td>9</td><td>Станислав Крушовалиев</td><td>01:18:52</td></tr>
          <tr><td>10</td><td>Любомир Матев </td><td>01:27:15</td></tr>
          <tr><td>11</td><td>Иван Стоянов</td><td>01:30:18</td></tr>
          <tr><td>12</td><td>Виктор Асенов</td><td>01:48:29</td></tr>

          <tr><th colSpan={3}>Adventure-пешеходци</th></tr>
          <tr><td>1</td><td>Симона Хесапчиева </td><td>02:02:09</td></tr>
          <tr><td>2</td><td>Констанс Хесапчиева  </td><td>02:02:09</td></tr>
          <tr><td>3</td><td>Христина Несторова  </td><td>DNF</td></tr>

          <tr><th colSpan={3}>Детско трасе момчета</th></tr>
          <tr><td>1</td><td>Цветомир Пахарски </td><td>16:27:02</td></tr>
          <tr><td>2</td><td>Владимир Драганов</td><td>17:40:22</td></tr>
          <tr><td>3</td><td>Даниел Драганов </td><td>17:49:08</td></tr>
          <tr><td>4</td><td>Стефан Стойчев</td><td>17:56:12</td></tr>
          <tr><td>5</td><td>Матей Димитров </td><td>18:19:08</td></tr>

          <tr><th colSpan={3}>Детско трасе момичета</th></tr>
          <tr><td>1</td><td>София Пахарска</td><td>20:37:24</td></tr>
          <tr><td>2</td><td>Александра Рамирес</td><td>26:20:04</td></tr>
          <tr><td>3</td><td>Цветелина Христова </td><td>26:21:07</td></tr>


        </tbody>
      </Table>
    );
  }
 
  export default BelogradchikRun;

