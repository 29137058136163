import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { db } from "../utils/firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import AuthNav from "../auth/AuthNav"
const DeleteItem = () => {
  const [redirectNow, setRedirectNow] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState({});
  const storage = getStorage();
  console.log (id)
  const [docRef, setDocRef] = useState(doc(db, "competitionsInfo", id));
  console.log(item);
  useEffect(() => {
    const getItem = async () => {
      const data = await getDoc(docRef);
      setItem(data.data());
    };
    getItem();
  }, []);



  const [popUp, setPopUp] = useState(false);

   const deleteItem = async (e) => {
    
    e.preventDefault();
   if ( window.confirm(`Сигурни ли сте, че искате да изтриете\n${item.title} от базата данни`)){

    item.urls.map((item)=>{
    const desertRef = ref(storage, item);
    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        console.log("file deleted");
        setRedirectNow(true);
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      })
    })
    
    await deleteDoc(doc(db, "competitionsInfo", id))
    .then(window.alert(`Успешно изтрихте ${item.title} от вашата база данни`))
    .then(()=> navigate("/admin/all-competitions"));
  }
  };

  return (
    <>
 <AuthNav />

    {!popUp && (
  <FormWrapper>
     <h1 className="title">Изтрий артикул</h1>

     <h1 className="title">Име: <i>{item.title}</i> <br /> Описание:  <i>{item.date}</i></h1>
    
      <br />
      <Button onClick={deleteItem} type="submit" variant="outline-danger">Изтрий състезание</Button>

    </FormWrapper>
  )}
  {popUp && (
    <Success>
      Поздравеления, артикулът е изтрит успешно!
    </Success>
  )}
  </>
  )
}
const Input = styled.input ` 
width:150px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width:1200px;
  padding:20px;
  margin:0 auto;
  margin-top:80px;
  justify-content: center;
`

const Success = styled.div`
  padding: 20px;
  border: 2px solid #212529;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  margin-top: 20px;
`;
export default DeleteItem