import React, { useContext } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import Error from "../Error";
import { auth } from "../utils/firebase"
import { IsAuthenticated } from "../context/UserContext";


const Login = () => {
  const { user, setUser } = useContext(IsAuthenticated);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  console.log(user);
  const onLoginFormSubmitHandler = (e) => {
    e.preventDefault();

    const userName = e.target.userName.value;
    const password = e.target.password.value;

    console.log(userName, password);

    signInWithEmailAndPassword(auth, userName, password)
      .then((userCredential) => {
        console.log("User successfully logged in");
        localStorage.setItem("uid", userCredential.user.uid);
        localStorage.setItem("email", userCredential.user.email);
        setUser(() => setUser(true));
        navigate("/admin/CPanel");
      })
      .catch((error) => {
        console.log(error);
        setError(error.code.slice(5).toUpperCase());
      });
  };

  return (
    <Container>
      <SubContainer1>
        {user ? (
          <p>
            You are already logged in. You can
            <Link to="/auth/signOut">
              <b>Sign out</b>
            </Link>{" "}
            or continue to{" "}
            <Link to="/admin/cpanel">
              <b>Cpanel</b>
            </Link>
          </p>
        ) : (
          <form onSubmit={onLoginFormSubmitHandler}>
            <InputContainer>
              <NavTitle>
                <label htmlFor="email">Email:</label>
              </NavTitle>
              <Input id="email" type="text" name="userName" />
            </InputContainer>
            <InputContainer>
              <NavTitle>
                <label htmlFor="password">Password:</label>
              </NavTitle>
              <Input id="password" type="password" name="password" />
            </InputContainer>
            <InputContainer>
              <NavTitle>
                <label htmlFor="password"></label>
              </NavTitle>
              <SubmitButton type="submit" value="Login" />
            </InputContainer>
            <Error error={error} />
          </form>
        )}
      </SubContainer1>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  border-top: 1px solid white;
  height: calc(100vh - 150px);
`;

const NavTitle = styled.span`
  font-size: 18px;
  min-width: 200px;
  margin-right: 5px;
  padding-bottom: 5px;
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Input = styled.input`
  display: flex;
  justify-content: center;
  padding: 5px;
  min-width: 200px;
  height: 40px;
`;
const SubContainer1 = styled.div`
  padding: 50px;
  width: 50%;
  background-color: #ffffff;
  flex-grow: 1;
  margin-top: 25vh;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const SubmitButton = styled.input`
  background-color: #ffffff;
  border: 1px solid #000000;
  display: inline-block;
  cursor: pointer;
  color: #39393f;
  min-width: 200px;
  height: 40px;
  font-size: 16px;
  padding: 5px;
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
    color: #ffffff;
    background-color: lightblue;
    background-color: #39393f;
  }
`;

export default Login;
