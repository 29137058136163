import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import ToggleButton from "./ToggleButton"
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs,  query, orderBy } from "firebase/firestore";
import { db } from "../utils/firebase";
import {motion} from "framer-motion"


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const News = () => {
 
  const [expanded, setExpanded] = React.useState(false);
  const [allNews, setAllNews] = useState([]);
  const newsDataRef = collection(db, `news`);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const getAll = async () => {
      const setAllDataNews = await getDocs(query(newsDataRef, orderBy("date", "desc")));
      setAllNews(
        setAllDataNews.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      )}
      getAll()
      
    }, []);
console.log (allNews)
  return (
    <Container 

    >
      {allNews.map((item) => {
        console.log (item.urls)
          console.log (item)
          return <Card sx={{ width: 400 }}   mt={1} variant="body1" align="left" style={{margin:"5px"}}>
          <CardHeader
            title= {item.author}
            subheader= {item.date}
          />
          <CardMedia
            component="img"
            height="194"
            image={item.urls[0]}
            alt="Paella dish"
          />
          <CardContent>
            <p>{item.title}</p>
        <Typography style={{ fontSize:"14px", textAlign:"Justify",  fontFamily: "Montserrat"}}>
          {item.description.slice(0, 130)}...
         <ToggleButton props={item.description.slice(130)}/>
         </Typography>
         </CardContent>
        </Card>
        })}
     
    </Container>
  )
}

const Container = styled(motion.div)` 
   padding:10px;
   background-color:#e9ecef;
   display: flex;
   justify-content:center;
   flex-wrap:wrap;
`


export default News
