import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { IsAuthenticated } from "../context/UserContext";
const AuthNav = () => {
  const { user } = useContext(IsAuthenticated);
  console.log (user)
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_ACCESS_KEY
  console.log (apiKey)
  console.log (process.env.REACT_APP_ACCESS_KEY)

  return (
    <Container>
      
      {user ? (
        <>
          {user.uid==apiKey ?<Button onClick={() => navigate("/admin/cpanel")}>C-panel</Button> : ""}
          {user.uid==apiKey ?<Button onClick={() => navigate("/admin/add-item")}>Добави снимка</Button> : ""}
           {user.uid==apiKey ?<Button onClick={() => navigate("/admin/all-items")}>Всички снимки</Button> : ""}
           {user.uid==apiKey ?<Button onClick={() => navigate("/admin/add-competition")}>Добави състезание</Button> : ""}
           {user.uid==apiKey ? <Button onClick={() => navigate("/admin/all-competitions")}>Всички състезания</Button> : ""}
           {user.uid==apiKey ? <Button onClick={() => navigate("/admin/messages")}>Съобщения</Button> : ""}
           {user.uid==apiKey ?<Button onClick={() => navigate("/admin/add-news")}>Добави новина</Button> : ""}
           {user.uid==apiKey ? <Button onClick={() => navigate("/admin/all-news")}>Всички новини</Button> : ""}
          <Button onClick={() => navigate("/auth/sign-out")}>Sign out</Button>
        </>
      ) : (
        <Button onClick={() => navigate("/auth/login")}>Log in</Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`;
const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid #000000;
  display: inline-block;
  cursor: pointer;
  color: #39393f;
  min-width: 200px;
  height: 40px;
  font-size: 16px;
  padding: 5px;
  transition: 0.5s;
  margin-left: 5px;
  &:hover {
    transition: 0.5s;
    color: #ffffff;
    background-color: lightblue;
    background-color: #39393f;
  }
`;
export default AuthNav;
