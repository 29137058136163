import React from 'react'
import styled from "styled-components"
import {motion} from "framer-motion"
import {footerAnimation, pageAnimation} from '../animation'


const Footer = () => {
  return (
    <BottomNavigation 
    variants={pageAnimation}
    initial="hidden" 
    animate="show"
    exit="exit"
   
    >
 <AllRights>© Copyright 2022 BG Events & Guiding</AllRights>

</BottomNavigation>
  )
}

const BottomNavigation = styled(motion.div)`

  background-color: #484748;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
 `

const AllRights = styled.p`
margin-top:15px;
   text-decoration: none;
  display: inline-block;
  padding: 4px 10px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 200;
  color: #ffffff;
`;
export default Footer