import React, {useContext, useEffect, useState} from 'react'
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components"
import { db } from "../utils/firebase";
import { collection, getDocs } from "firebase/firestore";
import { IsAuthenticated } from "../context/UserContext";

import AuthNav from "../auth/AuthNav";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MdDelete, MdModeEdit } from 'react-icons/md';

const AllNews = () => {

    const navigate = useNavigate();
    const { user } = useContext(IsAuthenticated);

    const [cups, setCups] = useState([]);
    const CupsCollectionRef = collection(db, "news");
    useEffect(() => {
      const getAll = async () => {
        const setDataAllCups = await getDocs(CupsCollectionRef);
        setCups(
          setDataAllCups.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      };
      getAll();
    }, []);
  return (
    <Wrapper>

<AuthNav />
      
      <TableContainer component={Paper}>
      <Table   sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead >
          <TableRow >

            <TableCell align="center">Заглавие</TableCell>
            <TableCell align="center">Дата</TableCell>
            <TableCell align="center">Изтрий</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cups.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell align="center">{row.title }</TableCell>
              <TableCell align="center">{row.date }</TableCell>
              <TableCell align="center">
                <Link className="see-more" to={`/admin/delete-news/${row.date}`}>
                  <MdDelete style={{fontSize:"24px", color:"#212529", cursor:"pointer"}}/>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Wrapper>

  )
}


const Wrapper = styled.div `
display: flex;
flex-wrap: wrap;
justify-content: center;
margin-top:80px;
`
const Span = styled.span` 
width:200px;
margin:10px;
`
const ItemsContainer = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;
export default AllNews