import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Form from "react-bootstrap/Form";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

import { updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { IsAuthenticated } from "../context/UserContext";
import { doc, getDoc } from "firebase/firestore";
import Alert from "react-bootstrap/Alert";
import { collection, addDoc } from "firebase/firestore";

export default function ParticipationButton({ props }) {
  const { user } = useContext(IsAuthenticated);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [identification, setIdentification] = useState(props.identification);

  const docRef = doc(db, "competitionsInfo", identification);
  const [dataCompetition, setDataCompetition] = useState([]);
  useEffect(() => {
    const getCompetition = async () => {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setDataCompetition(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getCompetition();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  console.log(dataCompetition);
  console.log(dataCompetition["participants"]);
  const handleSubmit = async (e) => {
    console.log(user);

    e.preventDefault();
    setIdentification(props.identification);
    let participantCompetition = props.title;
    let participantName = e.target.names.value;
    let participantEmail = e.target.email.value;
    let participantAge = e.target.age.value;
    let participantRoute = e.target.route.value;
    let phoneNumber = e.target.phoneNumber.value;
    const participantInfo = {
      [participantName]: [
        participantCompetition,
        participantName,
        participantEmail,
        participantRoute,
        participantAge,
        phoneNumber,
      ],
    };
    const new_obj = { ...dataCompetition["participants"], ...participantInfo };

    console.log(new_obj);

    const docRef = doc(db, "competitionsInfo", identification);
    await updateDoc(docRef, {
      participants: new_obj,
    })
      .then(async () => {
        const docRef = await addDoc(collection(db, "Email"), {
          to: participantEmail,
          cc: "bgeventsandguiding@gmail.com",
          message: {
            subject: `Участие в състезание ${participantCompetition}`,
            html: `Здравейте, <br /> Записахте се успешно за ${props.title}. <br /> За повече информация телефон - 0878 259 772. <br />От екипа на BG Events & Guiding Ви желаем успех! `,
          },
        });
        console.log("Document written with ID: ", docRef.id);
      })
      .then(() => setShow(true))
      .then(() => setShowForm(false));
  };
  return (
    <div>
      <Button
        variant="outlined"
        style={{ color: "inherit", borderColor: "inherit" }}
        onClick={handleClickOpen}
      >
        Запиши се
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <IconButton
          style={{ width: "40px", margin: "5px" }}
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        {showForm ? (
          <DialogForm id="dialogForm">
            <DialogTitle
              id="responsive-dialog-title"
              style={{ textAlign: "center" }}
            >
              {props.title}
            </DialogTitle>
            <Form style={{ padding: "20px" }} onSubmit={handleSubmit}>
              <Form.Control type="hidden" />
              <Form.Label>Избери трасе</Form.Label>
              <Form.Select aria-label="Избери трасе" name="route" required>
                <option></option>
                {props.routes.map((item) => {
                  return item != "" ? <option value={item}>{item}</option> : "";
                })}
              </Form.Select>

              <br />
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email..."
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Телефонен номер</Form.Label>
                <Form.Control
                  type="tel"
                  name="phoneNumber"
                  placeholder="Телефонен номер..."
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="names">
                <Form.Label>Две имена</Form.Label>
                <Form.Control
                  name="names"
                  type="text"
                  placeholder="Две имена..."
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="age">
                <Form.Label>Години</Form.Label>
                <Form.Control
                  type="number"
                  name="age"
                  placeholder="Години..."
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  style={{ color: "inherit", borderColor: "inherit" }}
                  type="submit"
                >
                  Запиши се
                </Button>
              </Form.Group>
            </Form>
          </DialogForm>
        ) : (
          <Alert show={show} variant="success">
            <InfoText>
              Успешно се записахте за {props.title}. Желаем Ви успех!
            </InfoText>
            <hr />`
          </Alert>
        )}
      </Dialog>
    </div>
  );
}

const DialogForm = styled.div`
  display: block;
`;
const InfoText = styled.p`
  text-align: center;
`;
