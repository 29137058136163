import React, { useState, useContext } from "react";
import styled from "styled-components";
import { collection, addDoc } from "firebase/firestore";
import { motion } from "framer-motion";
import { db } from "../utils/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { Navigate } from "react-router-dom";
import Error from "../Error";
import AuthNav from "../auth/AuthNav";

import { IsAuthenticated } from "../context/UserContext";

const AddItem = () => {
  const { user } = useContext(IsAuthenticated);
  const [error, setError] = useState("");
  const [errorRedirect, setErrorRedirect] = useState("");
  const [redirectNow, setRedirectNow] = useState(false);
  const [imgText, setImgText] = useState("Uplaod image!");
  const itemId = uuidv4();
  const storage = getStorage();

  const [imageUrl, setImageUrl] = useState("");

  const onSubmitHandler = (e) => {
    console.log(user);
    if (user) {
      e.preventDefault();
      let item_date = new Date();
      let width = Number(e.target.width.value);
      let height = Number(e.target.height.value);
      let gallery = e.target.gallery.value;
      let item_img_url = e.target.item_img_url.files[0];
      let key = new Date();
      console.log(item_img_url);
      const imageRef = ref(storage, `${gallery}/${itemId}.jpg`);


      uploadBytes(imageRef, item_img_url).then(() => {
        getDownloadURL(ref(storage, `${gallery}/${itemId}.jpg`)).then((url) => {
          setImageUrl(url);
          console.log(url);
          console.log("Uploaded a blob or file!");
          console.log(url);
          let urlFull = url;

          AddData(urlFull, itemId, item_date, width, height, gallery, key);
        });
      });
    } else {
      e.preventDefault();
      setError(() => `You are not logged in.`);
    }




    async function AddData(
      urlFull,
      itemId,
      item_date,
      width,
      height,
      gallery,
      key
    ) {
      try {
        const docRef = await addDoc(collection(db, gallery), {
          src: urlFull,
          itemId,
          item_date: item_date,
          width: width,
          height: height,
          gallery: gallery,
          key: key,
        });
        console.log("Document written with ID: ", docRef.id);
        setRedirectNow(true);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  return redirectNow ? (
    <Navigate to="/admin/all-items" />
  ) : (
    <Container>
      <AuthNav />
{user &&
      <form onSubmit={onSubmitHandler}>
        <InputContainer>
          <NavTitle>
            <p alt="noImage" id="myimg" name="userProfilePic">
              {imgText}
            </p>
          </NavTitle>

          <Input
            type="file"
            name="item_img_url"
            className="custom-file-input"
            id="inputButton"
            onChange={() => setImgText("Image is loaded")}
            required
          />
        </InputContainer>
        <InputContainer>
          <NavTitle>
            <label htmlFor="item_title">Gallery: </label>
          </NavTitle>
          <Select
            id="gallery"
            type="text"
            defaultValue=""
            name="gallery"
            required
          >
            <option></option>
            <option value="Awards">Награди</option>
            <option value="opletnqRun2022">Оплетня Run 2022</option>
            <option value="kobileniskali2022">Кобилени скали 2022</option>
            <option value="MidjurRun2022">Миджур 2022</option>
            <option value="MaguraRun_2022">Магура run 2022</option>
          </Select>
        </InputContainer>
        <InputContainer>
          <NavTitle>
            <label htmlFor="Price">Width:</label>
          </NavTitle>
          <Input type="number" name="width" required />
        </InputContainer>
        <InputContainer>
          <NavTitle>
            <label htmlFor="Price">Height:</label>
          </NavTitle>
          <Input type="number" name="height" required />
        </InputContainer>
        <InputContainer>
          <NavTitle>
            <label htmlFor=""></label>
          </NavTitle>
          <SubmitButton type="submit" value="Add item" />
        </InputContainer>
        <Error error={error} errorRedirect={errorRedirect} />
      </form>
      }
    </Container>
  );
};

const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;

const NavTitle = styled.span`
  display: flex;
  font-size: 18px;
  width: 200px;
  margin-right: 5px;
  align-items: center;
  padding-bottom: 5px;
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Input = styled(motion.input)`
  display: flex;
  justify-content: center;
  padding: 5px;
  min-width: 200px;
  height: 40px;
`;

const Select = styled.select`
  display: flex;
  justify-content: center;
  padding: 5px;
  min-width: 200px;
  height: 40px;
`;

const SubmitButton = styled.input`
  background-color: #ffffff;
  border: 1px solid #000000;
  display: inline-block;
  cursor: pointer;
  color: #39393f;
  min-width: 200px;
  height: 40px;
  font-size: 16px;
  padding: 5px;
  height: 40px;
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
    color: #ffffff;
    background-color: lightblue;
    background-color: #39393f;
  }
`;

export default AddItem;
