import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { db } from "../utils/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore"; 

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import AuthNav from "../auth/AuthNav";

const AddNews = () => {
  const [popUp, setPopUp] = useState(false);
  const storage = getStorage();
  const [images, setImages] = useState([])
  const [urls, setUrls] = useState([])
  const [title, setTitle] = useState([])

  const [author, setAuthor] = useState("")

  const [date, setDate] = useState("")
  const [description, setDescription] = useState("")
  const navigate = useNavigate();
  
  
  const handleChangeInput = (e)=>{
    for (let i =0; i<e.target.files.length; i++){
      const newImage = e.target.files[i]
      newImage["id"] = uuidv4()
      setImages((prevState)=>[...prevState, newImage])
    }
  
  }
  const onSubmitHandler = async (e) => {
    e.preventDefault();
   setAuthor(e.target.author.value)
   setTitle(e.target.title.value)
   setDate(e.target.date.value)
   setDescription(e.target.description.value)
   

for (let i=0; i<images.length; i++) {
    const storageRef = ref(storage, `news/${e.target.date.value}/${images[i].name}`)
     uploadBytes(storageRef, images[i]).then(()=>{
      getDownloadURL(storageRef)
        .then((url) => {
        console.log (url)
        setUrls((urls)=>[...urls, url])
       })})
    }

  }

  useEffect(()=>{
    const uploadDoc = async()=>{
      
      await setDoc(doc(db, "news", date), 
      {urls,
      author,
      title,
      date,
      description}).then(setPopUp(true))
}

    uploadDoc()
  }, [urls])

  return (
    <Wrapper>
    <AuthNav />
    {!popUp && (
  <FormWrapper>
     <h1 className="title">Добави новина</h1>
<form onSubmit={onSubmitHandler} id="form">
  <Form.Label htmlFor="author">Автор</Form.Label>
  <Form.Control
        type="text"
        name = "author"
        aria-describedby="passwordHelpBlock"
        placeholder="Автор..."
      />
        <br />
      <Form.Label htmlFor="name">Дата</Form.Label>
      <Form.Control
        type="text"
        name = "date"
        id="date"
        aria-describedby="passwordHelpBlock"
        placeholder="Дата..."
      />
        <br />
      <Form.Label htmlFor="name">Заглавие</Form.Label>
      <Form.Control
        type="text"
        name = "title"
        aria-describedby="passwordHelpBlock"
        placeholder="Заглавие..."
      />
        <br />
        <Form.Label htmlFor="description">Описание</Form.Label>
        <Form.Control
          name = "description" 
          id="description"
          type="text-area"
          as="textarea"
          placeholder="Описание..."
          style={{ height: '100px' }}
        />
        <br />
        <Form.Label>Избери файлове</Form.Label>
        <Form.Control id = "item_img_url"  name = "item_img_url" type="file" multiple onChange={handleChangeInput} />
      <br />
      <Button type="submit" variant="outline-success">Добави новина</Button>
    </form>
    </FormWrapper>
  )}
  {popUp && (
    <Success>
      Поздравеления, новината е добавено успешно!<br />     <br />
      <Button  variant="outline-success"  onClick = {()=> navigate("/auth")}>
        Обратно към списък c-panel
      </Button>
    </Success>
  )}
  </Wrapper>
  )
}

const Wrapper = styled.div`
margin-top:80px;
`
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width:1200px;
  padding:20px;
  margin:0 auto;
  margin-top:80px;
  justify-content: center;
`

const Success = styled.div`
  padding: 20px;
  border: 2px solid #212529;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  text-align: center;
  margin:0 auto;
  margin-top: 80px;
  width:90%;
`;
export default AddNews