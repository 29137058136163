
import './App.css';
import React, {useState, useEffect} from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { IsAuthenticated } from "./Components/context/UserContext"
import { auth } from "./Components/utils/firebase"

import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from "./Components/Nav"
import Home from "./Components/Home"

import { AnimatePresence } from "framer-motion"
import Gallery from './Components/Gallery';
import Results from './Components/Results';
import AboutUs from './Components/AboutUs';

import Messages from './Components/admin/Messages';
import Login from './Components/auth/Login';
import CPanel from './Components/auth/CPanel';
import AllItems from './Components/admin/AllItems'
import  SignOut  from './Components/auth/SignOut'
import AddItem from './Components/admin/AddItem'
import AddResults from './Components/admin/AddCompetition';
import Competitions from "./Components/Competitions"
import AllCompetitions from './Components/admin/AllCompetitions';
import DeleteItem from './Components/admin/DeleteItem';
import AddNews from './Components/admin/AddNews';
import AllNews from './Components/admin/AllNews';
import DeleteNews from './Components/admin/DeleteNews';
import GalleryDetailed from './Components/GalleryDetailed';



function App() {
  const location = useLocation()
  console.log (location)
  const [user, setUser] = useState({});
  useEffect(() => {
    auth.onAuthStateChanged(setUser);
  }, [user]);

  return (
    <IsAuthenticated.Provider value={{ user, setUser }}>

    <Nav />
    <AnimatePresence exitBeforeEnter >
      <Routes location={location} key={location.pathname}>

        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/sign-out" element={<SignOut />} />
        <Route path="/auth" element={<CPanel />} />
        <Route path="/admin" element={<CPanel />} />
        <Route path="/admin/cpanel" element={<CPanel />} />
        <Route path="/admin/all-items" element={<AllItems />} />
        <Route path="/admin/all-competitions" element={<AllCompetitions />} />
        <Route path="/admin/add-item" element={<AddItem />} />
        <Route path="/admin/messages" element={<Messages />} />
        <Route path="/admin/add-competition" element={<AddResults />} />
        <Route path="/admin/delete-item/:id" element={<DeleteItem />} />
        <Route path="/admin/add-news" element={<AddNews />} />
        <Route path="/admin/all-news" element={<AllNews />} />
        <Route path="/admin/delete-news/:id" element={<DeleteNews />} />

        <Route path="/" element={<Home />} />
        <Route path="/gallery/gallery-detailed/:id" element={<GalleryDetailed />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/results" element={<Results />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/competitions" element={<Competitions />} />

      </Routes>

    </AnimatePresence>

  </IsAuthenticated.Provider>
  );
}

export default App;
