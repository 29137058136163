import React, { useState } from "react";
import styled from "styled-components";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { NavLink } from "react-router-dom";
import Alert from '@mui/material/Alert';
const Nav = () => {
  const [popUp, setPopUp] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const changePopUp = () =>{
    if (popUp==true){
      document.getElementById("thisWrapper").style.display = "none";
      setPopUp(false)
     // orderLengthRef.current= orderLength
    }
  }
  return (
    <Container>
       <StyledLinkLogo
          onClick={() => setIsOpen(!isOpen)}
          to="/"
          id="logo"
        >
          BG Events & Guiding
        </StyledLinkLogo>
      <Burger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </Burger>
      <Logo id="Logo" isOpen={isOpen}>
        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/"
          exact="true"
          className="main-nav"
        >
          Начало
        </StyledLink>

        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/competitions"
          exact="true"
        >
          Състезания
        </StyledLink>
       
        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/gallery"
          exact="true"
        >
          Галерия
        </StyledLink>
        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/results"
          exact="true"
        >
          Резултати
        </StyledLink>
        <StyledLink
          onClick={() => setIsOpen(!isOpen)}
          to="/about"
          exact="true"
        >
          За нас
        </StyledLink>
        <Link onClick={() => {setIsOpen(!isOpen); window.open(
            "https://www.facebook.com/bgeventsandguiding",
            "_blank"
          )}}>
         <FacebookRoundedIcon  />
        </Link>
      </Logo>
    </Container>
  );
};

const Burger = styled.div`
  width: 30px;
  height: 30px;
  justify-content: space-around;
  flex-flow: column nowrap;
  margin-right: 10px;
  display: none;
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
    margin-left: 10px;
    
  }
  div {
    width: 30px;
    height: 4px;
    background-color: ${({ isOpen }) => (isOpen ? `#cccccc` : `#cccccc`)};
    border-radius: 2px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    
    &:nth-child(1) {
      transform: ${({ isOpen }) => (isOpen ? `rotate(45deg)` : `rotate(0)`)};
    }
    &:nth-child(2) {
      transform: ${({ isOpen }) =>
        isOpen ? `translateX(100%)` : `translateX(0)`};
      opacity: ${({ isOpen }) => (isOpen ? `0` : `1`)};
    }
    &:nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? `rotate(-45deg)` : `rotate(0)`)};
    }
  }
`;
const StyledLinkLogo = styled(NavLink)`
  text-decoration: none;
  display: inline-block;
  position: relative;
  cursor: pointer;

  @media (max-width: 868px) {
    display: none;

  }
  
`;

const StyledLink = styled(NavLink)`
    text-decoration: none;
    display: inline-block;
    padding: 19px 19px;
    font-size: 0.8rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    line-height: 20px;
    color: #ffffff;
    white-space: nowrap;
    width:100%;
  &.active {
    background: rgba(31, 31, 31, 0.71);
  }
  &:hover{
    background-color: rgba(96, 96, 96, 0.5);
    transition: all .3s linear;
    color: #ffffff;
  }
`;
const Link = styled.div`
    text-decoration: none;
    display: inline-block;
    padding: 15px 15px;
    font-size: 1rem;
    line-height: 28px;
    color: #ffffff;
    width:100%;
    cursor: pointer;
  &.active {
    background: rgba(31, 31, 31, 0.71);
  }
  &:hover{
    background-color: rgba(96, 96, 96, 0.5);
    transition: all .3s linear;
  }
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 10px;
    content: "";
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    background: rgb(244, 220, 206);
    background: radial-gradient(
      circle,
      rgba(244, 220, 206, 1) 0%,
      rgba(255, 255, 255, 0.30062447342218135) 100%
    );
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
 

  @media (max-width: 768px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    top: 60px;
    background: rgba(85, 85, 85, 0.85);
    color: #ffffff;
    transition: all 0.5s ease-in-out;
    transform: ${({ isOpen }) =>
      isOpen ? `translateX(0px)` : `translateX(100%)`};
  }
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  z-index: 999;
  justify-content: space-around;
  width: 100%;
  height: 60px;
  background: #484748;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
const AlertWrapper = styled.div`

`
export default Nav;
