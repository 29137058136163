import React, {useContext, useEffect, useState} from 'react'
import styled from "styled-components"
import {motion} from "framer-motion"
import {footerAnimation, pageAnimation} from '../animation'
import { cubeAnimation } from '../animation';
import {MdOutlineLocationOn}  from 'react-icons/md';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import { db } from "./utils/firebase"
import { collection, getDocs } from "firebase/firestore";
import ToggleButton from './addOns/ToggleButton';
import Footer from "./Footer"
import { query, orderBy } from "firebase/firestore";
import ParticipationButton from './addOns/ParticipationButton';
import AllParticipants from './addOns/AllParticipants';
import { IsAuthenticated } from "./context/UserContext";


const Competitions = () => {
  const { user } = useContext(IsAuthenticated);
  const [competitions, setCompetitions] = useState([]);
  
  const competitionsRef = collection(db, "competitionsInfo");
  useEffect(() => {
    const getAll = async () => {
      const setAllCompetitions = await getDocs(query(competitionsRef, orderBy("id")));
      setCompetitions(
        setAllCompetitions.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getAll();
  }, []);
console.log (competitions)
    return(
 <>
    <Container 
    variants={pageAnimation} 
    initial="hidden" 
    animate="show"
    exit="exit"
    style={{background:"#fff", overflowY:"hidden"}}
    >
     
     <Row   xs={1} md={3} className="g-1" style={{maxWidth:"100vw"}}>
         {competitions.map((item )=>{
  return(
    <Col>
      <Card as={motion.Card} variants={cubeAnimation}  border="light" bg="light" >
        <Carousel interval={null} indicators={false}>
            <Carousel.Item > 
            <img
              className="carousel-img"
              src={item.urls[0]}
              alt="First slide"
            />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="carousel-img"
                src={item.urls[1]}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="carousel-img"
                src={item.urls[2]}
                alt="Third slide"
              />
            </Carousel.Item>
        </Carousel>
            <Card.Body  >
              <Card.Title>
                {item.title} <br />
              <SubTitle>{item.subtitle}</SubTitle>

              
              </Card.Title>
<LiElement style={{textAlign:"center", padding:"15px"}}>
<ParticipationButton props={item}/>
{user ? <AllParticipants props={item}/> :" "}
              </LiElement>
              <LiElement > 
                    <CalendarMonthIcon style={{fontSize:"26px", color:"gray"}}/> 
                    <InfoText>
                    {item.date}
                    </InfoText>
            </LiElement>
              <LiElement> 
              <QueryBuilderIcon style={{fontSize:"26px", color:"gray"}}/>
                    <InfoText>
                      Старт {item.hour} часа
                    </InfoText>
            </LiElement>
              <LiElement>
                <MdOutlineLocationOn style={{fontSize:"26px", color:"gray"}}/>
              <InfoText>Старт/Финал: {item.place}</InfoText>
              </LiElement>
              <LiElement>
              <ToggleButton props={item.description}/>
            </LiElement>
            </Card.Body >
          </Card>
          </Col>
      
          )})}
          
</Row>
  
    </Container>
    <Footer />
    </>
  )
}

const Participate = styled.button`

`
const SubTitle = styled.div`
    font-size:14px;
    color:#484748;
    margin-top:10px;
   
`
const Container = styled(motion.div)` 
    overflow-x: hidden;
    margin-top:70px;
    min-height: 100vh;
`

const LiElement = styled.li` 
    font-size: 0.9rem;
    list-style:none ;
  padding:10px;
`
const InfoText = styled.span`
padding:0px;
`
export default Competitions