
import Table from 'react-bootstrap/Table';

function MidjurRun2022() {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        
        <tr><th>Място</th><th>Име</th><th>Време</th></tr>
      </thead>
      <tbody>
      

        <tr><th colSpan={3}>Трасе за Катерачи МЪЖЕ до 39 години</th></tr>
        <tr><td>1</td><td>Александър Александров </td><td>01:04:00</td></tr>
        <tr><td>2</td><td>Николай Младенов</td><td>01:04:30</td></tr>
        <tr><td>3</td><td>Николай Ралчов</td><td>01:18:25</td></tr>

        <tr><th colSpan={3}>Трасе за Катерачи МЪЖЕ 40+</th></tr>
        <tr><td>1</td><td>Цветко Митов </td><td>00:54:34</td></tr>
        <tr><td>2</td><td>Цветан Тодоров</td><td>00:55:04</td></tr>
        <tr><td>3</td><td>Емил Йорданов</td><td>00:55:53</td></tr>
        <tr><td>4</td><td>Цветомир Велковски</td><td>00:57:16</td></tr>
        <tr><td>5</td><td>Владислав Маринов</td><td>01:09:20</td></tr>
        <tr><td>6</td><td>Елвис Стоянов</td><td>01:21:40</td></tr>
        <tr><td>7</td><td>Милен Кънчев</td><td>01:39:04</td></tr>
        <tr><td>8</td><td>Даниел Йорданов</td><td>01:40:43</td></tr>
        <tr><td>9</td><td>Асен Христов</td><td>01:42:21</td></tr>

        <tr><th colSpan={3}>Трасе за Катерачи ЖЕНИ до 39 години</th></tr>
        <tr><td>1</td><td>Бела Дулева</td><td>01:05:32</td></tr>
        <tr><td>2</td><td>Венелина Емилова</td><td>01:06:36</td></tr>
        <tr><td>3</td><td>Констанс Хесапчиева</td><td>01:13:00</td></tr>
        <tr><td>4</td><td>Камелия Каменова</td><td>01:22:00</td></tr>

        <tr><th colSpan={3}>Трасе за Катерачи ЖЕНИ 40+</th></tr>
        <tr><td>1</td><td>Анна Йорданова </td><td>01:42:20</td></tr>
        <tr><td>2</td><td>Даниела Герасимова </td><td>01:44:59</td></tr>
       
      </tbody>
    </Table>
  );
}

export default MidjurRun2022;

