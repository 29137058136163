import React from 'react'
import styled from 'styled-components';
import A from "../files/HompageImages/1.JPG";
import B from "../files/HompageImages/3.JPG";
import D from "../files/HompageImages/2.JPG";
import {motion} from "framer-motion"
import Carousel from 'react-bootstrap/Carousel';
import {pageAnimation} from "../animation"
import News from './addOns/News';
import Sponsors from './addOns/Sponsors';
import Footer from "./Footer"

const Home = () => {
  return (
    <Container 
        variants={pageAnimation} 
        initial="hidden" 
        animate="show"
        exit="exit"
        style={{background:"#fff"}}
       >
    <Carousel fade id="carousel">
    <Carousel.Item>
        <img
          className="d-block w-100 cover"
          src={A}
          alt="First slide"
        />

        <Carousel.Caption>
          <h3>Belogradchik trail Run Balloon fiesta 2024</h3>
          <p>Бягане сред магията на Белоградчишките скали!</p>
          <p>НДЕЛЯ, 22 СЕПТЕМВРИ 2024 Г. ОТ 10:00 Ч.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 cover"
          src={B}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Borovitza Bike and Run</h3>
          <p>Магично приключение сред феерия от каменни великани!</p>
          <p>СЪБОТА, 2 НОЕМВРИ 2024 Г. ОТ 10:00 Ч.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 cover"
          src={D}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Borovitza Ultra</h3>
          <p>Планинско бягане с гледки към Искърското дефиле!</p>
          <p>НЕДЕЛЯ, 3 НОЕМВРИ 2024 Г. ОТ 08:00 Ч.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
     {/*<News />*/}  
       <Sponsors />
  
       <Footer />
    </Container>
  )
}

const Container = styled(motion.div)`
   overflow: 'auto'
`
export default Home