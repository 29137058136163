import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import flowers from "../files/Backgrounds/flowers.jpg"
import styled from "styled-components"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {motion} from "framer-motion";
import { pageAnimation } from '../animation';
import Footer from "./Footer"
import OpletnqRun2022 from '../CompetitionsResults/2022_09_Opletnq';
import KoprenRun_2022 from '../CompetitionsResults/2022_06_Kopren';
import KobSteni_2022 from '../CompetitionsResults/2022_08_KobileniSteniRun'
import MidjurRun2022 from '../CompetitionsResults/2022_08_MidjurRun';
import MaguraRun_2022Res from '../CompetitionsResults/2022_07_MaguraRun';
import Vratzata_2022Res from '../CompetitionsResults/2022_11_VratzataRun';
import Koledno_Ochin_DolRes from '../CompetitionsResults/2022_12_Koledno_Ochin_Dol';
import BelogradchikRun_2022Res from '../CompetitionsResults/2022_11_BelogradchikRun';
export default function Results() {
 
  return (
    <>
  <Wrapper 
  variants={pageAnimation} 
  initial="hidden" 
  animate="show"
  exit="exit"
  >
  <ContainerResults >
  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Коледно Очин Дол 2022.12.11</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Koledno_Ochin_DolRes />
        </AccordionDetails>
      </Accordion>
  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Белоградчик Run 2022.11.27</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <BelogradchikRun_2022Res />
        </AccordionDetails>
      </Accordion>
  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Вратцата Run 2022.11.20</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Vratzata_2022Res />
        </AccordionDetails>
      </Accordion>
  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Оплетня Run 2022.09.11</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <OpletnqRun2022 />
        </AccordionDetails>
      </Accordion>
  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Кобилини стени Run 2022.08.28</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <KobSteni_2022 />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Миджур Run / Вертикален километър 2022.08.07</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MidjurRun2022 />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Магура Run</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MaguraRun_2022Res />
        </AccordionDetails>
      </Accordion>




    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography><Title>Копрен run 2022.06</Title></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <KoprenRun_2022 />
        </AccordionDetails>
      </Accordion>
  
    </ContainerResults>  
   
    </Wrapper>
    <Footer />  
    </>
  );
}
const Wrapper = styled(motion.div)` 
  padding:5px;
  background-image:url(${flowers});
  margin-top:60px;
  padding-top:20px;
  height: calc(100vh - 100px);
`
const ContainerResults = styled(motion.div)` 
 
`
const Title = styled.h3`
  font-family: "Montserrat", sans-serif;
  font-size:1rem;
`
