export const pageAnimation = {
    hidden: {
        opacity: 0,
      },
      show: {
        opacity: 1,
        transition: {
          duration: 0.5,
          when: "beforeChildren",
          staggerChildren: 0.25,
        },
      },
      exit: {
        opacity: 0,
        transition: {
          duration: 0.4,
        },
      },
    };

export const cubeAnimation = {
    hidden:{
        y:200,
        opacity: 0
    },
    show: {
        y:0,
        opacity: 1,
        transition:{
            duration:0.75, ease:"easeOut"
        }
    }
}

export const footerAnimation = {
  hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 3,
        when: "beforeChildren",
        staggerChildren: 1.35,
      },
    },
    exit: {
      opacity: 0,
   
      transition: {
        duration: 0.5,
      },
    },
  };