import React from 'react'
import styled from "styled-components"
import beer from "../../files/sponsors/beer.png"
import belcolade from "../../files/sponsors/belcolade.png"
import adventure from "../../files/sponsors/adventure.jpg"
import stenata from "../../files/sponsors/stenata.png"
const Sponsors = () => {
  return (
    <Container >
        
        <Title>Благодарим за подкрепата на нашите партньори
        </Title>
    <SponsorsContainer >
    <Image src={adventure} onClick={() =>
        window.open(
          "https://new.adventure-bg.com/",
          "_blank"
        )} />
      <Image src={beer} onClick={() =>
        window.open(
          "https://www.beerpustinyak.com/",
          "_blank"
        )} />
        <Image src={belcolade} onClick={() =>
        window.open(
          "https://www.belcolade.com/en",
          "_blank"
        )} />
        <Image src={stenata} onClick={() =>
        window.open(
          "https://www.stenata.com",
          "_blank"
        )} />
        
        </SponsorsContainer>
    </Container>
  )
}

const Container = styled.div`

 margin-top:10px;
 background-color: #fff;
 padding:10px;
 height:auto;
  
`
const SponsorsContainer = styled.div`
display: flex;
@media (max-width: 768px) {  
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }  
`
const Image = styled.img`
max-width:150px;
cursor:pointer;
  transition: transform 250ms, opacity 400ms;
  margin:20px;
  &:hover {
    transform: scale(1.1);
    opacity: 1;
    font-size: 60px;
  }
  `

const Title = styled.h3` 
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size:30px;
  text-align: left;
  width:100vw;
  padding:10px;
  color: #212529;
  border-bottom:3px solid #212529;;
`
export default Sponsors