import React, { useState, useEffect, useContext } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { db } from "../utils/firebase";
import { IsAuthenticated } from "../context/UserContext";
import { query, doc, getDoc } from "firebase/firestore";

export default function AllParticipants({props}) {
  const [redirectNow, setRedirectNow] = useState(false);
  const { user } = useContext(IsAuthenticated);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  console.log (props)


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


let arr= Object.values(props["participants"])
console.log (arr)
  return (
    <Wrapper>
      <Button variant="outlined" style={{color:"inherit", borderColor:"inherit"}} onClick={handleClickOpen}>
        Записани състезатели
      </Button>
      
      <Dialog
        fullScreen={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      >

        <IconButton
              style={{width:"40px", margin:"5px"}}
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />

        </IconButton>
        <DialogTitle id="responsive-dialog-title" style={{textAlign:"center"}}>
          {props.title}
          <TableHead>
          <TableRow>
            <TableCell colSpan="5" align="left">Общ брой участници: {arr.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Имена</TableCell>
            <TableCell align="left">e-mail</TableCell>
            <TableCell align="left">Трасе</TableCell>
            <TableCell align="left">Години</TableCell>
            <TableCell align="left">телефонен номер</TableCell>
          </TableRow>
        </TableHead>
          {arr.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell  align="left" size="medium">{row[1] }</TableCell>
              <TableCell align="left">{row[2] }</TableCell>
              <TableCell align="left">{row[3] }</TableCell>
              <TableCell align="left">{row[4] }</TableCell>
              <TableCell align="left">{row[5] }</TableCell>
            </TableRow>
          ))}
        </DialogTitle>

      </Dialog>
     
    </Wrapper>
  );

}
const Wrapper = styled.div`
  margin-top:10px;
`
